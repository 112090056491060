<template>
    <div>
        <v-dialog
            v-if="visible"
            v-model="visible"
            transition="dialog-top-transition"
            scrollable
            persistent
            max-width="700"
        >
            <v-card class="modal-maincard">
                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">
                    <v-card 
                        flat
                    >
                        <v-card-text>
                            <v-form ref="form" lazy-validation>

                                <v-row no-gutters>
                                    <v-col cols="12" sm="12" md="3">
                                        <label class="f-label">{{ $t('Контрольный_срок') }}</label>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="9">
                                        <v-menu 
                                            v-model="controleDateMenu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    :value="innerLimit | formattedDate"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on" 
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    class="datepick-input"
                                                    :rules="requiredRule"
                                                >
                                                </v-text-field>
                                            </template>

                                            <v-date-picker
                                                v-model="innerLimit"
                                                @input="controleDateMenu = false"
                                                color="teal"
                                                :min="controlDateRange.Min"
                                                :first-day-of-week="1"
                                            >
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>

                                <v-row no-gutters>
                                    <v-col cols="12" sm="12" md="3">
                                        <label class="f-label">{{ $t('Ответственный') }}</label>
                                    </v-col>
                                    <v-col  cols="12" sm="12" md="9">
                                        <div class="icon-click-row-group icrg-align-center"> 
                                            <div
                                                @click="onExecuterSelect"
                                            >
                                                <v-icon 
                                                    small 
                                                    v-tooltip.left-center="selectExecuterTooltip"
                                                >
                                                    fas fa-edit
                                                </v-icon>
                                            </div>
                                                <div class="chipAreaNoData" v-if="!executer"></div>
                                                <v-workplace-chip
                                                    v-else
                                                    :key="executer.workplaceId"
                                                    :id="executer.workplaceId"
                                                    :name="executer.name"
                                                    :main="true"
                                                />
                                        </div>
                                    </v-col>     
                                </v-row>

                            </v-form>        
                        </v-card-text>

                    </v-card>
                    
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="cyan"
                        text
                        depressed
                        @click="ok"
                        v-if="isValid && executer"
                    >
                        {{ $t("Ок") }}
                    </v-btn>

                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed
                        @click="cancel"
                    >
                        {{$t("Отмена")}}
                    </v-btn>

                </v-card-actions>

            </v-card>

        </v-dialog>

    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
//import i18n from '@/i18n'
import sys from '@/services/system';
//import _ from 'lodash';


export default {
    name: "SendReportOnExecutionDlg",
    data: () => ({
        title: "Отправка_на_исполнение",
        options: {
            color: 'grey lighten-3',
            width: 400,
            zIndex: 200,
            noconfirm: false,
        },

        controleDateMenu: false,
        isValid: false,
    }),
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    computed: {
        ...mapGetters('reportsgeneration/dialogs/sendreportonexecution', { visible: 'isVisible', valid: 'isValid', }),
        ...mapGetters('global/auth', { isCommonServiceEnabled: 'isCommonServiceEnabled', userInfo: 'getUserInfo' }),
        ...mapGetters({ requiredRule: 'getRequiredRule' }),  
        executer: {
            get: function() {
                return this.$store.getters['reportsgeneration/dialogs/sendreportonexecution/getExecuter'];
            },
            set: function(v) {
                this.$store.commit('reportsgeneration/dialogs/sendreportonexecution/SET_EXECUTER', v);
            }
        },
        innerLimit: {
            get: function() {
                return this.$store.getters['reportsgeneration/dialogs/sendreportonexecution/getControlDate'];
            },
            set: function(v) {
                this.$store.commit('reportsgeneration/dialogs/sendreportonexecution/SET_CONTROLDATE', v);
            }
        },
        selectExecuterTooltip() {
            return this.$t("Выбрать_исполнителя");
        },
        controlDateRange() {

            let Min = '';
            let min = this.$moment(this.getTimestamp).startOf('day');

            if (min && min.isValid()) {
                Min = min.format('YYYY-MM-DD');
            }
            return { Min };
        },
    },
    methods: {
        ...mapActions('reportsgeneration/dialogs/sendreportonexecution', ['ok', 'cancel']),
        ...mapActions('global/attachments', ['validateFile']),
        formatDate (source) {
            return this.$moment(source).format('DD.MM.YYYY');
        },
        async onExecuterSelect() {
            try
            {
                let implicitExclude = []; // неявно исключить (нет в выбранных, недоступен для выбора)

                if (this.from)
                    implicitExclude.push(this.userInfo.workplaceId);

                let selectMemberParams =  {
                    title: this.$t("Выбор_ответственного_исполнителя"),
                    includeInner: true,
                    includeOuter: this.isCommonServiceEnabled,
                    multiple: false,
                    selected: this.executer ? [this.executer.workplaceId] : [],
                    implicitExclude
                }

                this.executer = await this.$store.dispatch('global/dialogs/selectMembersNew/open', selectMemberParams);
                
            }
            catch (ex)
            {
                console.log(ex.message);
            }
        },
        validateFormMethod(){
            this.isValid = this.$refs?.form?.validate();  
        }
    },
    async created() {
        (async() => {
            while(typeof this.$refs.form === 'undefined')
                await new Promise(resolve => setTimeout(resolve, 100));

            this.validateFormMethod();
        })();
    },
    updated () {
        this.validateFormMethod();
    }
}
</script>